// dashboard.js

let engagementChart, sentimentChart;
let lastUpdateTime = Date.now();
const updateInterval = 2000; // Update every 2 seconds

document.addEventListener('DOMContentLoaded', function() {
  initDarkMode();
  initCharts();
  initLanguageSelector();
  updateQuickStats();
  startUpdates();
});

function initDarkMode() {
  const darkModeToggle = document.getElementById('darkModeToggle');
  const body = document.body;

  if (localStorage.getItem('darkMode') === 'enabled') {
    body.classList.add('dark-mode');
    darkModeToggle.innerHTML = '<i class="fas fa-sun"></i>';
  }

  darkModeToggle.addEventListener('click', function() {
    body.classList.toggle('dark-mode');
    
    if (body.classList.contains('dark-mode')) {
      localStorage.setItem('darkMode', 'enabled');
      darkModeToggle.innerHTML = '<i class="fas fa-sun"></i>';
    } else {
      localStorage.setItem('darkMode', null);
      darkModeToggle.innerHTML = '<i class="fas fa-moon"></i>';
    }

    updateChartColors();
  });
}

function initCharts() {
  const engagementCtx = document.getElementById('engagementChart').getContext('2d');
  engagementChart = new Chart(engagementCtx, {
    type: 'line',
    data: {
      labels: Array(6).fill('').map(() => new Date().toLocaleTimeString()),
      datasets: [{
        label: 'User Engagement',
        data: Array(6).fill(50),
        borderColor: '#4a90e2',
        backgroundColor: 'rgba(74, 144, 226, 0.2)',
        tension: 0.4
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          max: 100,
          ticks: {
            stepSize: 20
          }
        }
      },
      plugins: {
        legend: {
          display: false
        }
      },
      animation: {
        duration: 500,
        easing: 'easeOutQuad'
      }
    }
  });

  const sentimentCtx = document.getElementById('sentimentChart').getContext('2d');
  sentimentChart = new Chart(sentimentCtx, {
    type: 'doughnut',
    data: {
      labels: ['Positive', 'Neutral', 'Negative'],
      datasets: [{
        data: [70, 20, 10],
        backgroundColor: ['#2ecc71', '#f39c12', '#e74c3c']
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 500,
        easing: 'easeOutQuad'
      }
    }
  });

  updateChartColors();
}

function updateChartColors() {
  const isDarkMode = document.body.classList.contains('dark-mode');
  const textColor = isDarkMode ? '#f0f0f0' : '#333';

  engagementChart.options.scales.x.ticks.color = textColor;
  engagementChart.options.scales.y.ticks.color = textColor;
  engagementChart.update();

  sentimentChart.options.plugins.legend.labels.color = textColor;
  sentimentChart.update();
}

function initLanguageSelector() {
  const languageSelect = document.getElementById('languageSelect');
  if (languageSelect) {
    languageSelect.addEventListener('change', (e) => {
      alert(`Language changed to ${e.target.value}. Translations would be applied here.`);
    });
  }
}

function updateQuickStats() {
  const elements = {
    activeSessionsCount: document.getElementById('activeSessionsCount'),
    totalInteractions: document.getElementById('totalInteractions'),
    avgSessionDuration: document.getElementById('avgSessionDuration'),
    customerSatisfaction: document.getElementById('customerSatisfaction')
  };

  if (elements.activeSessionsCount) {
    elements.activeSessionsCount.textContent = Math.floor(Math.random() * 50 + 20);
  }
  if (elements.totalInteractions) {
    elements.totalInteractions.textContent = Math.floor(Math.random() * 1000 + 500);
  }
  if (elements.avgSessionDuration) {
    elements.avgSessionDuration.textContent = (Math.random() * 10 + 5).toFixed(1) + ' min';
  }
  if (elements.customerSatisfaction) {
    elements.customerSatisfaction.textContent = (Math.random() * 1 + 4).toFixed(1) + '/5.0';
  }
  
  console.log('Quick stats updated at:', new Date().toLocaleTimeString());
}

function updateCharts() {
  const now = Date.now();
  if (now - lastUpdateTime < updateInterval) {
    return;
  }
  lastUpdateTime = now;

  // Update engagement chart
  const newEngagementData = engagementChart.data.datasets[0].data.slice(1);
  const lastValue = newEngagementData[newEngagementData.length - 1];
  const newValue = Math.min(100, Math.max(0, lastValue + (Math.random() - 0.5) * 15));
  newEngagementData.push(newValue);

  engagementChart.data.labels = engagementChart.data.labels.slice(1);
  engagementChart.data.labels.push(new Date().toLocaleTimeString());
  engagementChart.data.datasets[0].data = newEngagementData;

  // Update sentiment chart
  const newSentimentData = sentimentChart.data.datasets[0].data.map(value => 
    Math.max(0, value + (Math.random() - 0.5) * 10)
  );
  const total = newSentimentData.reduce((a, b) => a + b, 0);
  sentimentChart.data.datasets[0].data = newSentimentData.map(value => (value / total) * 100);

  // Update charts
  engagementChart.update();
  sentimentChart.update();

  updateQuickStats();

  console.log('Charts updated at:', new Date().toLocaleTimeString());
}

function startUpdates() {
  setInterval(updateCharts, updateInterval);
}

// Avatar-related functions (placeholder implementations)
function startConversation() {
  console.log('Starting conversation...');
  // Implement avatar conversation start logic here
}

function stopConversation() {
  console.log('Stopping conversation...');
  // Implement avatar conversation stop logic here
}

function resetConversation() {
  console.log('Resetting conversation...');
  // Implement avatar conversation reset logic here
}

// Event listeners for avatar controls
document.addEventListener('DOMContentLoaded', function() {
  const startButton = document.getElementById('start-conversation');
  const stopButton = document.getElementById('stop');
  const resetButton = document.getElementById('reset');

  if (startButton) startButton.addEventListener('click', startConversation);
  if (stopButton) stopButton.addEventListener('click', stopConversation);
  if (resetButton) resetButton.addEventListener('click', resetConversation);
});